import axios from 'axios'
import { message } from 'antd'
import runtimeConfig from '../config'
// Add a request interceptor
console.log(runtimeConfig.MONEY_SERVER, '=== env', !runtimeConfig.MONEY_SERVER ? 'ms': 'pure');
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // config.headers.userCode = 'token';
    // config.headers.userCode = localStorage.getItem('userCode') || '';
    // config.headers.userId = localStorage.getItem('userId') || '';
    config.headers.authorization = localStorage.getItem('authorization') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmI0ODAyNzA2NjgxYTM2NDg2MzI1ZTciLCJlbWFpbCI6IjI2Mjc0NzUzMUBxcS5jb20iLCJwaG9uZSI6IjEzMDUxNTgwMTUzIiwibmlja25hbWUiOiJBbGljaHMiLCJpYXQiOjE2NTYyOTMzMTZ9.wMjIsdDvQbiQJR1mgqyCoJ_OqKPrsvWGoL0O_SCOsQE';
    config.url = /^\/webcore/.test(config.url) ? config.url : '/webcore' + config.url;
    config.url = !runtimeConfig.MONEY_SERVER ? config.url : runtimeConfig.MONEY_SERVER  + config.url;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  if (response.status !== 200) {
    message.error('服务异常')
  }
  return response?.data
})