import superagent from "superagent";
const host = "http://service.yuntun-bj.com/webcore/service/campus";

export const SET_CURRENT_ACTION = "bsfusion/SET_CURRENT_ACTION";
export const SET_MESSAGE_ACTION = "bsfusion/SET_MESSAGE_ACTION";
export const SET_GROUP_ACTION = "bsfusion/SET_GROUP_ACTION";
export const SET_GROUP_LIST_ACTION = "bsfusion/SET_GROUP_LIST_ACTION";
export const SET_ACTION_LIST_ACTION = "bsfusion/SET_ACTION_LIST_ACTION";
export const SET_MODIFY_ACTION = "bsfusion/SET_MODIFY_ACTION";
export const SET_GROUP_ANALYSIS_LIST = "bsfusion/SET_GROUP_ANALYSIS_LIST";
export const SET_GROUP_SUMMARY = "bsfusion/SET_GROUP_SUMMARY";

const initialState = false ? {
    currentAction: {},
    messageInfo: {},
    currentGroup: {},
    groupList: [],
    groupSummary: {},
    actionList: [],
    modifyAction: {},
    groupAnalysisList: []
} : {
    currentAction: {
        action_headpic: '',
        action_rules: [
            {count: 1, price: 998}],
        action_count_type: 1,
        action_total_members: 10,
        action_addr: '江西省赣州市安远县',
        corp_phone: '13051580153',
        action_corp: '云吞北京',
        action_duration_time: '2024.6.15 ~ 2024.7.1'
    },
    messageInfo: {},
    currentGroup: {},
    groupList: [1,1,1,1,1],
    groupSummary: {
        total_member_count: 1,
        new_member_count: 10,
    },
    actionList: [],
    modifyAction: {},
    groupAnalysisList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_ACTION:
            return {
                ...state,
                currentAction: action.data
            };
        case SET_MESSAGE_ACTION:
            return {
                ...state,
                messageInfo: action.data
            };
        case SET_GROUP_ACTION:
            return {
                ...state,
                currentGroup: action.data
            };
        case SET_GROUP_LIST_ACTION:
            return {
                ...state,
                groupList: action.data
            };
        case SET_ACTION_LIST_ACTION:
            return {
                ...state,
                actionList: action.data
            };
        case SET_MODIFY_ACTION:
            return {
                ...state,
                modifyAction:
                    state.actionList.find(item => item._id === action.id) || {}
            };
        case SET_GROUP_ANALYSIS_LIST:
            return {
                ...state,
                groupAnalysisList: action.data
            };
        case SET_GROUP_SUMMARY:
            return {
                ...state,
                groupSummary: action.data
            };
        default:
            return state;
    }
};

export const getCurrentAction = id => dispatch =>
    new Promise(resolve => {
        superagent.get(host + "/fetch_action/" + id).then(({ body }) => {
            dispatch({
                type: SET_CURRENT_ACTION,
                data: body.data
            });
            resolve(body.data);
        });
    });
export const getCurrentGroup = id => dispatch =>
    new Promise(resolve => {
        superagent.get(host + "/fetch_group_info/" + id).then(({ body }) => {
            dispatch({
                type: SET_GROUP_ACTION,
                data: body.data
            });
            resolve(body);
        });
    });

export const showModel = (type, info, visible) => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: SET_MESSAGE_ACTION,
            data: { type, info, visible }
        });
    });

export const setMemberAction = (id, data) => dispatch =>
    new Promise(resolve => {
        superagent
            .post(`${host}/push_action/${id}`)
            .send(data)
            .then(({ body }) => {
                resolve(body);
            });
    });
export const setGroupMemberAction = (id, groupid, data) => dispatch =>
    new Promise(resolve => {
        superagent
            .post(`${host}/join_action/${id}/${groupid}`)
            .send(data)
            .then(({ body }) => {
                resolve(body);
            });
    });

export const setGroupListAction = (id, search_key) => dispatch =>
    new Promise(resolve => {
        superagent.get(host + "/fetch_group_list/" + id).then(({ body }) => {
            dispatch({
                type: SET_GROUP_LIST_ACTION,
                data: body.data.group_info_list
            });
            dispatch({
                type: SET_GROUP_SUMMARY,
                data: {
                    new_member_count: body.data.new_member_count,
                    total_member_count: body.data.total_member_count
                }
            });
            resolve(body);
        });
    });
export const searchGroupListAction = (id, search_key) => dispatch =>
    new Promise(resolve => {
        superagent
            .get(host + "/fetch_group_list/" + id)
            .query({ key: search_key })
            .then(({ body }) => {
                dispatch({
                    type: SET_GROUP_LIST_ACTION,
                    data: body.data.group_info_list
                });
                resolve(body);
            });
    });
export const getActionList = () => dispatch =>
    new Promise(resolve => {
        superagent.get(host + "/fetch_action/all").then(({ body }) => {
            dispatch({
                type: SET_ACTION_LIST_ACTION,
                data: body.data
            });
            resolve(body);
        });
    });
export const setActionInfo = data => dispatch =>
    new Promise(resolve => {
        superagent
            .post(`${host}/create_action`)
            .send(data)
            .then(({ body }) => {
                resolve(body);
            });
    });
export const updateActionInfo = (id, data) => dispatch =>
    new Promise(resolve => {
        superagent
            .post(`${host}/update_action/${id}`)
            .send(data)
            .then(({ body }) => {
                resolve(body);
            });
    });
export const setModifyAction = id => dispatch =>
    new Promise(resolve => {
        dispatch({
            type: SET_MODIFY_ACTION,
            id
        });
    });
export const getGroupAnalysisList = id => dispatch =>
    new Promise(resolve => {
        superagent
            .get(host + `/fetch_group_analysis_list/${id}`)
            .then(({ body }) => {
                dispatch({
                    type: SET_GROUP_ANALYSIS_LIST,
                    data: body.data
                });
                resolve(body);
            });
    });
