import React from "react";
import { Route, Routes, useRoutes, Link } from "react-router-dom";
import loadable from '@loadable/component';
import appConf from '@config/appConf';

const mapRoutesForUse = (routes) => {
  return routes.map(({ path, component, children }) => {
    const Component = loadable(() => import(`${component}`));

    return {
      path,
      element: <Component />,
      children: children && mapRoutesForUse(children)
    };
  });
};
const AppRoutes = () => useRoutes(mapRoutesForUse(appConf.routes));

export default AppRoutes;