// import Cookies from 'js-cookie';

export const AUTHENTICATE = 'auth/AUTHENTICATE';
export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
export const SET_DICT = 'auth/SET_DICT';

const initialState = {
    content: '',
    isAuthenticated: false,
    currentUser: {},
    dict: { depts: ['zhifu', 'zhuanbin'] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user
      };
   
    case SET_DICT:
      return {
        ...state,
        dict: action.payload
      }
    default:
      return state;
  }
};

export const setDict = dict => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_DICT, payload: dict})
  })