/**
 * 关于path路径
 * 终极解决方案：
 * RAZZLE_PREFIX=
 * PUBLIC_PATH=/
 */
export default [
  { name: "home", path: "/", component: "./Home/home" },
  {
    name: "info", path: "/info", component: "./Info", children: [
      { name: "april", path: "april", component: "./Info/Dairy/April", },
      { name: "may", path: "may", component: "./Info/Dairy/May" },
      { name: "sept", path: "sept", component: "./Info/Dairy/Sept" },
      { name: "oct", path: "oct", component: "./Info/Dairy/Oct" },
      { name: "nov", path: "nov", component: "./Info/Dairy/Nov" },
      { name: "dec", path: "dec", component: "./Info/Dairy/Dec" },
    ]
  },
  { name: "login", path: "login", component: "./Home/Login" },
  { name: "mlogin", path: "mlogin", component: "./MLogin" },
  { name: "regist", path: "regist", component: "./Regist" },
  { name: "p2c", path: "p2c", component: "./P2C" },
  {
    name: 'campus', path: '/campus', component: './Campus', children: [
      { name: 'dashboard', path: 'dashboard', component: './Campus/dashboard_copy' },
      { name: 'dynatic-test', path: 'test', component: './Campus/dynatic_formItem' },
      { name: 'drawer-test', path: 'drawer', component: './Campus/drawer_item' },
    ]
  },
  {
    name: 'dao', path: '/dao', component: './Dao', children: [
      { name: '11', path: '11', component: './Dao/11', children: [
        { name: '11detail', path: 'detail', component: './Dao/11/11detail' }
      ]},
      { name: '23', path: '23', component: './Dao/23', children: [
        { name: '23detail', path: 'detail', component: './Dao/23/23detail' }
      ]},
      { name: '37', path: '37', component: './Dao/37', children: [
        { name: '37detail', path: 'detail', component: './Dao/37/37detail' }
      ]},
      { name: '53', path: '53', component: './Dao/53', children: [
        { name: '53detail', path: 'detail', component: './Dao/53/53detail' }
      ]},
    ]
  },
  { name: 'stock', path: '/stock', component: "./P2C/stock" }
];



