// @ts-ignore
/* eslint-disable */
import request from 'axios';

/** 获取用户列表 手机号、邮箱、用户名、昵称、头像、身份证， return <user>[] GET /auth/dao/userlist */
export async function getAuthDaoUserlist(options) {
  return request('/auth/dao/userlist', {
    method: 'GET',
    ...(options || {}),
  });
}

/** KAS每日最高价&最低价列表 默认获取KAS每日最高价&最低价列表 GET /money/base/kaslist */
export async function getMoneyBaseKaslist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/kaslist', {
    method: 'GET',
    headers: {},
    params: { ...params },
    ...(options || {}),
  });
}

/** 货币兑换人民币汇率列表 默认获取美元兑换人民币的汇率列表 GET /money/base/ratelist */
export async function getMoneyBaseRatelist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/ratelist', {
    method: 'GET',
    headers: {},
    params: {
      // type has a default value: usd
      type: 'usd',
      // from has a default value: 1727712000000
      from: '1727712000000',
      ...params,
    },
    ...(options || {}),
  });
}

/** A股股票列表 默认获取A股股票列表 GET /money/base/stockA */
export async function getMoneyBaseStocka(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/stockA', {
    method: 'GET',
    headers: {},
    params: {
      // code has a default value: 000001
      code: '000001',
      ...params,
    },
    ...(options || {}),
  });
}

/** A股股票列表 - 名称 默认获取A股股票列表 - 名称 GET /money/base/stockANames */
export async function getMoneyBaseStockanames(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/stockANames', {
    method: 'GET',
    headers: {},
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** A股股票列表 - 高级查询 默认获取A股股票列表 - 高级查询 GET /money/base/stockAdvance */
export async function getMoneyBaseStockadvance(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/stockAdvance', {
    method: 'GET',
    headers: {},
    params: {
      // price has a default value: 3.14
      price: '3.14',
      // date_stamp has a default value: 1734019200000
      date_stamp: '1734019200000',
      // eqchar has a default value: =
      eqchar: '=',
      ...params,
    },
    ...(options || {}),
  });
}

/** trans openapis to single Returns format openapis GET /service/base/openapis */
export async function getServiceBaseOpenapis(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/service/base/openapis', {
    method: 'GET',
    params: {
      // resetTag has a default value: ScrmOpenAPI
      resetTag: 'ScrmOpenAPI',
      ...params,
    },
    ...(options || {}),
  });
}

/** 发送短信验证码 给指定手机号发送验证码, 并存入redis中等待校验, return boolean GET /auth/dao/send_smscode/${param0} */
export async function getAuthDaoSendSmscodePhone(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { phone: param0, ...queryParams } = params;
  return request(`/auth/dao/send_smscode/${param0}`, {
    method: 'GET',
    params: {
      // type has a default value: login
      type: 'login',
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取用户信息 脱敏返回用户信息 GET /auth/dao/user/${param0} */
export async function getAuthDaoUserUid(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { uid: param0, ...queryParams } = params;
  return request(`/auth/dao/user/${param0}`, {
    method: 'GET',
    headers: {},
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改用户信息 手机号(暂不实现)、邮箱(暂不实现)、用户名(暂不实现)、昵称、头像(暂不实现)、身份证(暂不实现) PUT /auth/dao/user/${param0} */
export async function putAuthDaoUserUid(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  body,
  options,
) {
  const { uid: param0, ...queryParams } = params;
  return request(`/auth/dao/user/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** Get db data Returns a db data item by the id passed in the path GET /money/base/db/${param0} */
export async function getMoneyBaseDbNum(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { num: param0, ...queryParams } = params;
  return request(`/money/base/db/${param0}`, {
    method: 'GET',
    headers: {},
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** Get rds data Returns a rds data item by the id passed in the path GET /money/base/rds/test */
export async function getMoneyBaseRdsTest(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  return request('/money/base/rds/test', {
    method: 'GET',
    headers: {},
    params: {
      // key has a default value: sms:login:code:13051580153
      key: 'sms:login:code:13051580153',
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取全部活动详情接口 fetch_action/all GET /service/campus/fetch_action/all */
export async function getServiceCampusFetchActionAll(options) {
  return request('/service/campus/fetch_action/all', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 根据活动id获取活动详情接口 fetch_action GET /service/campus/fetch_action/${param0} */
export async function getServiceCampusFetchActionActionId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { action_id: param0, ...queryParams } = params;
  return request(`/service/campus/fetch_action/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 根据团id获取该团信息接口 fetch_group_info GET /service/campus/fetch_group_info/${param0} */
export async function getServiceCampusFetchGroupInfoGroupId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { group_id: param0, ...queryParams } = params;
  return request(`/service/campus/fetch_group_info/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取全团信息接口 fetch_group_info GET /service/campus/fetch_group_list/${param0} */
export async function getServiceCampusFetchGroupListActionId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { action_id: param0, ...queryParams } = params;
  return request(`/service/campus/fetch_group_list/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 验证姓名是否存在 verify_name GET /service/campus/verify_name/${param0} */
export async function getServiceCampusVerifyNameActionId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { action_id: param0, ...queryParams } = params;
  return request(`/service/campus/verify_name/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 验证手机号是否存在 verify_phone GET /service/campus/verify_phone/${param0} */
export async function getServiceCampusVerifyPhoneActionId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  options,
) {
  const { action_id: param0, ...queryParams } = params;
  return request(`/service/campus/verify_phone/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 新增用户 昵称、手机号、邮箱 POST /auth/dao/user */
export async function postAuthDaoUser(body, options) {
  return request('/auth/dao/user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建活动，并返回活动标识 create_action POST /service/campus/create_action */
export async function postServiceCampusCreateAction(body, options) {
  return request('/service/campus/create_action', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 本地图片上传到oss upload_file POST /service/campus/upload_file */
export async function postServiceCampusUploadFile(body, options) {
  const formData = new FormData();

  Object.keys(body).forEach((ele) => {
    const item = body[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request('/service/campus/upload_file', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 团长开团，提交团长信息，并生成团标识返回 push_action POST /service/campus/push_action/${param0} */
export async function postServiceCampusPushActionActionId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  body,
  options,
) {
  const { action_id: param0, ...queryParams } = params;
  return request(`/service/campus/push_action/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 用户登录 登录获取鉴权信息, return <userinfo, token>{} POST /auth/dao/user/${param0}/login */
export async function postAuthDaoUserPhoneLogin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  body,
  options,
) {
  const { phone: param0, ...queryParams } = params;
  return request(`/auth/dao/user/${param0}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 团员根据团标识入团，返回团员列表信息 join_action POST /service/campus/join_action/${param0}/${param1} */
export async function postServiceCampusJoinActionActionIdGroupId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  body,
  options,
) {
  const { action_id: param0, group_id: param1, ...queryParams } = params;
  return request(`/service/campus/join_action/${param0}/${param1}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 团员根据活动id,团id,手机号退团，返回执行状态 quit_action POST /service/campus/quit_action/${param0}/${param1} */
export async function postServiceCampusQuitActionActionIdGroupId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params,
  body,
  options,
) {
  const { action_id: param0, group_id: param1, ...queryParams } = params;
  return request(`/service/campus/quit_action/${param0}/${param1}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
