import { createFromIconfontCN } from '@ant-design/icons';
import Cookies from 'js-cookie'

const performanceTokenKey = 'accessToken'
const TokenKey = 'authorization'
const roleKey = 'roleSet'

export function getPToken() {
  return Cookies.get(performanceTokenKey)
}
export function setPToken(token, date) {
  date = date || 3600
  var Ddate = new Date()
  Ddate.setTime(Ddate.getTime() + date * 1000)
  return Cookies.set(performanceTokenKey, token, { expires: Ddate })
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, date) {
  date = date || 3600
  var Ddate = new Date()
  Ddate.setTime(Ddate.getTime() + date * 1000)
  return Cookies.set(TokenKey, token, { expires: Ddate })
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRole() {
    return Cookies.get(roleKey)
  }
  
  export function setRole(setRoles) {
    if(setRoles) {
      const myArray = Array.from(setRoles);
      const myString = JSON.stringify(myArray);
      return Cookies.set(roleKey, myString)
    }
  }
  
  export function removeRole() {
    return Cookies.remove(roleKey)
  }

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

export const getPath = (path_name = '/') => {
    return typeof window !== 'undefined' ? ( window.env.NODE_ENV === 'production' ? process.env.RAZZLE_PREFIX + path_name : path_name ) : path_name
}

export const getDownloadServer = () => {
    return window.env.KNOWLEDGE_SERVER || '未设置.env中的 KNOWLEDGE_SERVER, 请设置'
}

export const convertTableData2TreeData = (I) => (id = 0, joint = '0', link = 'PARENT_ID', subJoint = '', O) => {
    if (!I) {
        I = [{ id: 1, parentId: 0, orderNum, address: "西湖区湖底公园1号", age: 32, isLeaf: true, key: "1", name: "胡彦斌1", nodeIndex: "0" }, ...{}]
        O = [{ key: '0-0', id: 1, name: '胡彦斌', children: [] }, ...{}]
        return O;
    }

    let joint_key = 1;
    O = I.filter(item => item[link] == id).map((item, nodeNum) => {
        const { icon, ...rest } = item
        let returnData = {
            ...rest,
            key: JSON.stringify({ id: item.id, url: item.url }),
            label: item.name
        }
        const children = convertTableData2TreeData(I)(item.id, `${joint}-${nodeNum}`, link, subJoint);
        return children?.length === 0 ? {
            ...returnData,
        } : {
            ...returnData,
            children
        }
    });
    return O
}