import routes from "./routes"
const _routes = routes.map(route => (process.env.NODE_ENV === 'production' ? {...route, path: process.env.RAZZLE_PREFIX + route.path} : route));

export const getRoutesMap = (routes, base) => {
  return routes.map( route => {
    if ('children' in route) {
      if (base === '/' || !base) {
        return [route.path, ...getRoutesMap(route.children, route.path) ]
      }
      else {
        return [base + route.path, ...getRoutesMap(route.children, base + route.path) ]
      }
    }

    if (base === '/' || !base) { 
      return route.path
    }
    else {
      return `${base}/${route.path}`
    }
  }).flat()
}
console.log(getRoutesMap(_routes));
export default {
  routes: _routes.filter(route => route.component),
  entries: _routes.filter(route => route.entry).map(route => ({ container: '#yourContainer', activeRule: route.path, ...route })),
  routesMap: getRoutesMap(_routes)
}