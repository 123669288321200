import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Routes from "./routes";
import { Layout, Menu, Breadcrumb, ConfigProvider } from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { getPath, convertTableData2TreeData } from "utils/luna-utils";
import API from "@/services/abudhabi";
import {
  IntlProvider,
  FormattedMessage,
  createIntl, createIntlCache, RawIntlProvider,
  useIntl,
} from "react-intl";
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import arEG from 'antd/locale/ar_EG';
import newEn from './lang/message/en';
import newZh from './lang/message/zh';
import newAr from './lang/message/ar';
import "./App.less";
import { useLocation, NavLink } from "react-router-dom";
const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

const validateMessages = {
  required: "'${label}' 是必选字段",
  // ...
};

const App = ({ history }) => {
  const location = useLocation();
  const [current, setCurrent] = useState("mail");
  const [menuList, setMenuList] = useState([]);
  let whiteList = ["/login", "/mlogin"];
  const userInfo = JSON.parse(
    typeof localStorage !== "undefined"
      ? localStorage.getItem("zskUserInfo") || "{}"
      : "{}"
  );
  const inData = {
    roleId: userInfo.roleId,
    userId: userInfo.userId,
  };
  const fetchMenuList = async () => {
    API.ScrmOpenAPI.getUserMenuUsingPOST({ data: { inData } }).then((res) => {
      const _menuList = res?.data?.data?.outData?.menuList;
      const TopMenus = _menuList.filter((menu) => menu.parentId === "TOP");
      const FullMenus = TopMenus.map((menu) => {
        const children = convertTableData2TreeData(_menuList)(
          menu.id,
          "TOP",
          "parentId"
        );
        return children.length
          ? {
            label: menu.name,
            key: JSON.stringify({ id: menu.id, url: menu.url }),
            children,
          }
          : {
            label: menu.name,
            key: JSON.stringify({ id: menu.id, url: menu.url }),
          };
      });

      console.log(FullMenus, "==== FullMenus");
      setMenuList(FullMenus);
    });
  };

  useEffect(() => {
    // fetchMenuList()
  }, [location.pathname]);

  const getLang = (lang) => {
    switch (lang) {
      case 'zh': return zhCN;
      case 'en': return enUS;
      case 'ar': return arEG;
      default: return zhCN;
    }
  }
  const getIntlConf = (lang) => {
    switch (lang) {
      case 'zh': return { locale: 'zh', messages: newZh };
      case 'en': return { locale: 'en', messages: newEn };
      case 'ar': return { locale: 'ar', messages: newAr };
      default: return { locale: 'zh', messages: newZh };
    }
  }

  const language = Cookies.get('locale');
  const [locale, setLocal] = useState(getLang(language));
  const [lang, setLang] = useState(language);
  const [intlConf, setIntlConf] = useState(getIntlConf(language));
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache()
  const intl = createIntl(intlConf, cache);


  return whiteList
    .concat(whiteList.map((item) => getPath(item) + "/"))
    .includes(location.pathname) ? (
      <RawIntlProvider value={intl}>
        <Routes />
      </RawIntlProvider>
  ) : (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <div className="navigator">
          <NavLink to="/" className="nav-item">
            首页
          </NavLink>
          {/* <NavLink to="/info" className="nav-item">
            归档
          </NavLink> */}
          {/* <NavLink to="/dao" className="nav-item">
            Dao
          </NavLink> */}
          <NavLink to="/p2c" className="nav-item">
            P2C
          </NavLink>
          <NavLink to="/stock" className="nav-item">
            A股
          </NavLink>
          {/* <NavLink to="/campus" className="nav-item">
            夏令营
          </NavLink> */}
        </div>
        <div className="user">
          <NavLink to="/login">登录</NavLink>
          {/* <span style={{ margin: "0 4px" }}>|</span>
          <NavLink to="/regist">注册</NavLink> */}
        </div>
        {/* <Menu
        theme="light"
        mode="horizontal"
        // selectedKeys={[current]}
        style={{ lineHeight: '64px' }}
        items={menuList}
        triggerSubMenuAction='click'
        onSelect={ e => {
          console.log(e.key, '==== select key')
        }}
        onClick={ e => {
          console.log(e.key, '=== key')
          setCurrent(e.key);
          const item = JSON.parse(e.key)
          history.push(getPath(item.url))
        }}
      /> */}
      </Header>
      <Content style={{ padding: 16 }}>
        <div
          style={{
            background: "#fff",
            padding: "16px 16px 0 16px",
            minHeight: 280,
          }}
        >
          <ConfigProvider form={{ validateMessages }} locale={zhCN}>
            <Routes />
          </ConfigProvider>
        </div>
      </Content>
      {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
    </Layout>
  );
};

export default App;
