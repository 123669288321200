import './public-path';
import './utils/interceptors';
import App from './App';
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM, { hydrate } from 'react-dom';
import createStore from './store';
import { container } from 'webpack';
// Create a store and get back itself and its history object
const { store, history } = createStore();

function render(props) {
  const { container } = props;
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={window.__POWERED_BY_QIANKUN__ ? '/fe-app' : '/'}>
        <App />
      </BrowserRouter>
    </Provider>
    ,
    container ? container.querySelector('#root') : document.querySelector('#root')
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({ container: null });
}

if (module.hot) {
  module.hot.accept();
}


export async function bootstrap(props) {
  console.log('[react16] react app bootstraped');
}

export async function mount(props) {
  console.log('[react16] props from main framework', props);

  // 将主应用的所有属性都挂到global这个对象上
  // global.$lunaProps = props;
  
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}
